<template>
  <v-container class="pa-0">
    <v-row class="content-area">
      <v-col
        class="btn-container"
        cols="12"
        align-self="center"
      >
        <v-btn
          class="btn-add mb-10"
          color="#00BFB4"
          dark
          @click="$router.replace('/new-location/map')"
        >
          클릭해서 추가
        </v-btn>
        <v-btn
          class="btn-add"
          color="#00BFB4"
          dark
          @click="$router.replace('/new-location/robot')"
        >
          현재 위치로 추가
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from '@/main.js';

  export default {
    name: 'NewLocationConfig',
    created() {
      EventBus.$on('toModifyLocation', locationId => {
        this.toModifyLocation(locationId);
      });
    },
    beforeDestroy() {
      EventBus.$off('toModifyLocation');
    },
    methods: {
      toModifyLocation(locationId) {
        this.$router.replace({
          name: 'ModifyLocation',
          params: {
            locationId: locationId
          }
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
.content-area {
  height: 100%;
}

.btn-container {
  height: 50% ;
  text-align: center;
}

.btn-add {
  height: 30% !important;
  width: 70%;
  border-radius: 10px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem !important;
  line-height: 117px;
  text-align: center;
}

@media (max-width: 1024px) {
  .btn-add {
    height: 40px !important;
    width: 100% !important;
    border-radius: 10px;
    font-size: 2rem !important;
  }

}
</style>
